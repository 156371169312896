<template>
  <section v-if="data && data.galery">
    <section class="floors">
      <div class="wrapper">
        <div class="slideshow">
          <slick :options="projectOptions">
            <div class="slide" v-for="(images, i) in data.galery" :key="i">
              <picture>
                <img :src="images.url" />
              </picture>
            </div>
          </slick>
          <div class="arrow-container prev prj">
            <arrow />
          </div>
          <div class="arrow-container next prj">
            <arrow />
          </div>
        </div>
        <div class="container">
          <div class="description">
            <h1>{{ data.type[lang] }}</h1>
            <div class="text">
              {{ data.text[lang] }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="contact-us">
      <picture class="background" v-if="data.learn_more.background">
        <img :src="data.learn_more.background.url" />
      </picture>
      <div class="container">
        <h2>{{ data.learn_more.heading[lang] }}</h2>
        <div class="sub">{{ data.learn_more.subheading[lang] }}</div>
        <a :href="lng + '/contact'">
          <button>{{ data.learn_more.button[lang] }}</button>
        </a>
      </div>
    </section>
  </section>
</template>
<script>
import arrow from "@/assets/svg/arrow";
import slick from "vue-slick";

import "slick-carousel/slick/slick.css";

export default {
  props: ["data", "lang"],

  components: {
    arrow,
    slick,
  },
  computed: {
    lng() {
      if (this.$parent.lang == "ru") {
        return "/ru";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      projectOptions: {
        centerMode: false,
        arrows: true,
        autoplay: false,
        mobileFirst: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: ".arrow-container.prev.prj",
        nextArrow: ".arrow-container.next.prj",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.floors {
  background-color: #212226;
}

.wrapper {
  display: flex;
  padding-top: 60px;
  align-items: center;
  color: #fff;
  flex-wrap: wrap;
  .slideshow {
    width: 100%;
    height: 60vh;
    position: relative;
    .next {
      transform: translate(-50%, -50%) rotate(180deg);
    }
    .prev {
      transform: translate(50%, -50%);
    }
    .slide {
      position: relative;
      height: 100%;
      picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: bottom;
        }
      }
    }
  }
  .description {
    padding: 57px 100px 107px 100px;
    .text {
      font-weight: 400;
      margin-top: 20px;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 21px;
      opacity: 0.75;
      column-count: 2;
      column-gap: 50px;
    }
  }
}
.contact-us {
  padding: 107px 0 162px 0;
  background-color: #242529;
  text-align: center;
  picture {
    img {
      object-position: bottom;
    }
  }
  h2 {
    text-align: center;
    font-size: 34px;
    line-height: 40px;
  }
  .sub {
    font-weight: 400;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    padding: 8px 0 30px;
  }
  button {
    text-transform: capitalize;
    width: 242px;
    height: 42px;
    border-radius: 24px;
    background: transparent;
    font-size: 14px;
    font-weight: 300;
    transition: 1s;
    color: #000;
    background-color: #fff;
    &:hover {
      background-color: #e0e7f0;
    }
  }
}
@media only screen and (max-width: 1100px) and (min-width: 300px) {
  .wrapper {
    flex-wrap: wrap;
    padding-top: 10vh;
    height: auto;
    .slideshow {
      width: 100%;
      //height: 40vh;
    }
    .description {
      padding: calc(100vw / 320 * 30) calc(100vw / 320 * 20);
      max-width: none;
      .text {
        column-count: 1;
        column-gap: 0px;
      }
    }
  }
}
@media only screen and (max-width: 600px) and (min-width: 300px) {
  h1 {
    font-size: calc(100vw / 320 * 30);
    line-height: calc(100vw / 320 * 36);
  }
  .wrapper {
    .description {
      .text {
        margin-top: calc(100vw / 320 * 10) !important;
        font-size: calc(100vw / 320 * 12);
        line-height: calc(100vw / 320 * 16);
      }
    }
  }
}
</style>
