import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import i18n from './i18n'
Vue.config.productionTip = false;
Vue.use(VueRouter);
new Vue({
  router,
  render: (h) => h(App),
  i18n,
  store
}).$mount("#app");
