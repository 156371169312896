<template>
  <section class="main" v-if="block_names.length">
    <div class="cont" v-for="(x, i) in 1" :key="i">
      <MenuComponent :names="block_names" :lang="lang" />
      <FooterComponent :lang="$route.params.lang" />
      <section ref="block" class="wall" id="block_1">
        <picture
          class="background"
          v-if="block_names[0] && block_names[0].background"
        >
          <img :src="block_names[0].background.url" />
        </picture>
        <div class="container">
          <div class="slide-number">
            <div class="current">01</div>
            <div class="total">/07</div>
          </div>
          <h1>{{ heading[lang] }}</h1>
        </div>
      </section>
      <section ref="block" class="catalog" id="block_2">
        <div class="container">
          <div class="slide-heading">
            <div class="slide-number">
              <div class="current">02</div>
              <div class="total">/07</div>
            </div>
            <h2>{{ block_names[1].link[lang] }}</h2>
          </div>

          <div class="grid">
            <div class="item-best" ref="doska">
              <picture class="background-animate" :class="{ active: doska }">
                <img src="@/assets/doska.png" alt="" />
              </picture>
              <div class="content">
                <div class="type">{{ $t("best-choise") }}</div>
                <h3 class="heading" v-if="best_choise[lang]">
                  {{ best_choise[lang] }}
                </h3>
                <div class="line"></div>
                <a :href="lng + '/' + 'trisslanu-parkets'"
                  ><button class="more">{{ $t("more") }}</button></a
                >
              </div>
            </div>
            <div class="sub-list">
              <div class="item" v-for="(choise, i) in sub_choises" :key="i">
                <picture class="background">
                  <img :src="choise.background.url" alt="" />
                </picture>
                <div class="name">
                  {{ choise.name[lang] }}
                </div>
                <div class="over">
                  <a :href="lng + '/' + floors[i]"
                    ><button>{{ $t("more") }}</button></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section ref="block" class="projects" id="block_3">
        <div class="container" v-if="projects.length">
          <div class="slide-heading">
            <div class="slide-number">
              <div class="current">03</div>
              <div class="total">/07</div>
            </div>
            <h2>{{ block_names[2].link[lang] }}</h2>
          </div>
          <div class="projects-wrap">
            <slick ref="slick" class="slideshow" :options="slickOptions">
              <div class="slide" v-for="(project, i) in projects" :key="i">
                <div class="content">
                  <div class="type">{{ $t("project") }}</div>
                  <div class="title">{{ project.title[lang] }}</div>
                  <div
                    class="description"
                    v-html="project.description[lang]"
                  ></div>
                  <div class="more" @click="openProject(i)">
                    <div>{{ $t("more") }}</div>
                    <arrow />
                  </div>
                </div>
                <picture class="background" v-if="project.images.length > 0">
                  <img :src="project.images[0].url" alt="" />
                </picture>
              </div>
            </slick>
            <div class="arrow-container prev">
              <arrow />
            </div>
            <div class="arrow-container next">
              <arrow />
            </div>
          </div>
        </div>
      </section>
      <div class="view-project" v-if="projectView.active">
        <div class="container">
          <div class="wrapper">
            <slick ref="slick" class="slideshow" :options="projectOptions">
              <div
                class="slide"
                v-for="(prj, i) in projects[projectView.projectID].images"
                :key="i"
              >
                <img alt="" :src="prj.url" />
              </div>
            </slick>
            <div class="arrow-container prev prj">
              <arrow />
            </div>
            <div class="arrow-container next prj">
              <arrow />
            </div>
          </div>
        </div>
        <div class="close" @click="projectView.active = false">
          <div></div>
          <div></div>
        </div>
      </div>
      <section ref="block" class="advantages" id="block_4">
        <picture class="background" v-if="block_names[3].background">
          <img :src="block_names[3].background.url" />
        </picture>
        <div class="container">
          <div class="slide-heading">
            <div class="slide-number">
              <div class="current">04</div>
              <div class="total">/07</div>
            </div>
            <h2>{{ block_names[3].link[lang] }}</h2>
          </div>
          <div class="advantage-grid">
            <div
              class="advantage-block"
              v-for="(adv, i) in advantages"
              :key="i"
            >
              <div class="advant-logo">
                <component v-bind:is="svg[i]"></component>
              </div>
              <div class="advant-heading">{{ adv.name[lang] }}</div>
              <div class="advant-text">{{ adv.description[lang] }}</div>
            </div>
          </div>
        </div>
      </section>
      <section ref="block" class="process" id="block_5">
        <picture class="background" v-if="block_names[4].background">
          <img :src="block_names[4].background.url" />
        </picture>
        <div class="container">
          <div class="slide-heading">
            <div class="slide-number">
              <div class="current">05</div>
              <div class="total">/07</div>
            </div>
            <h2>{{ block_names[4].link[lang] }}</h2>
          </div>
          <div class="steps">
            <div
              class="step-container"
              v-for="(steps, i) in work_process"
              :key="i"
            >
              <div class="circle">{{ i + 1 }}</div>
              <div class="text">{{ steps.step[lang] }}</div>
            </div>
          </div>
        </div>
      </section>
      <section ref="block" class="discounts" id="block_6">
        <div class="container">
          <div class="slide-heading">
            <div class="slide-number">
              <div class="current">06</div>
              <div class="total">/07</div>
            </div>
            <h2>{{ block_names[5].link[lang] }}</h2>
          </div>
          <button
            class="discount-container"
            v-for="(discount, i) in discounts"
            :key="i"
            ref="disc"
            @click="$refs.disc[i].classList.toggle('active')"
          >
            <picture class="background">
              <img :src="discount.background.url" />
            </picture>
            <div class="content">
              <div class="heading">{{ discount.header[lang] }}</div>
              <button class="read-more">
                <span>{{ $t("know-more") }}</span>
                <arrow />
              </button>
            </div>
            <div class="hidden" v-html="discount.text[lang]"></div>
          </button>
        </div>
      </section>
      <section ref="block" class="contacts" id="block_7">
        <div class="container">
          <div class="slide-heading">
            <div class="slide-number">
              <div class="current">07</div>
              <div class="total">/07</div>
            </div>
            <h2>{{ block_names[6].link[lang] }}</h2>
          </div>
          <div class="contacts-container">
            <div class="map">
              <iframe
                id="map"
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2177.018991412718!2d24.03669461597665!3d56.93134128088489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eed072f1874f21%3A0x293f45716cca668d!2sSpice+Home!5e0!3m2!1sru!2sus!4v1493224480335"
                border-radius="4"
                width="100%"
                height="100%"
                frameborder="0"
                style="border:0"
                allowfullscreen
                zoom="disable"
                scrollwheel="false"
              ></iframe>
            </div>
            <div class="contact-info">
              <img src="@/assets/logo.png" class="logo" />
              <div class="content">
                <div class="heading">{{ contacts.shop_name[lang] }}</div>
                <p>
                  <span>{{ contacts.address[lang].fields }}: </span
                  >{{ contacts.address[lang].value }}
                </p>
                <br />
                <p v-for="(phone, i) in contacts.phone" :key="i">
                  <span>{{ phone[lang].name }}:</span>
                  {{ phone[lang].value }}
                </p>
                <br />
                <p>
                  <span>{{ contacts.email[lang].fields }}: </span
                  >{{ contacts.email[lang].value }}
                </p>
                <br />
                <p v-for="(hours, i) in contacts.phone_copy" :key="i">
                  <span>{{ hours[lang] }}:</span>
                  {{ hours.value }}
                </p>
                <br />
                <p>
                  <span>{{
                    contacts.germany.official_representative[lang]
                  }}</span>
                </p>
                <p>{{ contacts.germany.name }}</p>
                <p>
                  <span>{{ contacts.germany.phone[lang] }}:</span>
                  {{ contacts.germany.phone.value }}
                </p>
                <p v-if="contacts.germany.email[lang]">
                  <span>{{ contacts.germany.email[lang] }}:</span>
                  {{ contacts.germany.email.value }}
                </p>
                <br />
                <p>
                  <span>{{ contacts.details.name[lang] }}</span>
                </p>
                <p v-for="(detail, i) in contacts.details.detail" :key="i">
                  <span>{{ detail[lang] }}:</span>
                  {{ detail.value }}
                </p>
                <br />
                <p>
                  <span>{{ contacts.bank_details.name[lang] }}</span>
                </p>
                <p v-for="(detail, i) in contacts.bank_details.detail" :key="i">
                  <span>{{ detail[lang] }}:</span>
                  {{ detail.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- -->
    </div>
  </section>
</template>

<script>
import MenuComponent from "@/components/MenuComponent";
import FooterComponent from "@/components/FooterComponent";
import { mapState } from "vuex";
import adv1 from "@/assets/svg/adv1";
import adv2 from "@/assets/svg/adv2";
import adv3 from "@/assets/svg/adv3";
import adv4 from "@/assets/svg/adv4";
import search from "@/assets/svg/search";
import arrow from "@/assets/svg/arrow";
import slick from "vue-slick";
import "slick-carousel/slick/slick.css";

export default {
  name: "Home",
  components: {
    MenuComponent,
    FooterComponent,
    adv1,
    adv2,
    adv3,
    adv4,
    search,
    arrow,
    slick,
  },
  data: () => ({
    doska: false,
    projectView: {
      active: false,
      projectID: 0,
    },
    discountValue: [],
    slickOptions: {
      centerMode: false,
      arrows: true,
      autoplay: false,
      dots: true,
      mobileFirst: true,
      slidesToShow: 3,
      prevArrow: ".arrow-container.prev",
      nextArrow: ".arrow-container.next",
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 300,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
      ],
    },
    projectOptions: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,

      prevArrow: ".arrow-container.prev.prj",
      nextArrow: ".arrow-container.next.prj",
    },
    floors: [
      "laminats",
      "vinils",
      "riekstkoka-vairogparkets",
      "makslinieciskais",
    ],
    svg: ["adv1", "adv2", "adv3", "adv4"],
  }),
  methods: {
    openProject(i) {
      this.projectView.active = true;
      this.projectView.projectID = i;
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.locale,
      advantages: (state) => state?.fields.advantages || [],
      best_choise: (state) => state?.fields.best_choise || {},
      block_names: (state) => state?.fields.block_names || [],
      contacts: (state) => state?.fields.contacts || {},
      discounts: (state) => state?.fields.discounts || [],
      form_background: (state) => !!state?.fields.form_background,
      form_fields: (state) => state?.fields.form_fields || {},
      form_heading: (state) => state?.fields.form_heading || {},
      form_sub_heading: (state) => state?.fields.form_sub_heading || {},
      heading: (state) => state?.fields.heading || {},
      sub_choises: (state) => state?.fields.sub_choises || [],
      work_process: (state) => state?.fields.work_process || [],
      projects: (state) => state.projects,
    }),
    lng() {
      if (this.lang == "ru") {
        return "/ru";
      } else {
        return "";
      }
    },
    lang() {
      return this.$route.fullPath.includes("/ru") ? "ru" : "lv";
    },
  },
  mounted() {
    const el = this.$refs.doska;
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.doska = entry.isIntersecting;
      });
    });
    if (el) {
      el.forEach((el) => intersectionObserver.observe(el));
    }
  },
};
</script>
<style lang="scss">
picture.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.3;
  img {
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
}
.slide-number {
  display: flex;
  line-height: 1;
  margin-right: 8px;
  .current {
    font-family: "Cinzel", serif;
    color: #b8bac1;
    font-size: 60px;
  }
  .total {
    font-size: 20px;
    color: #81869880;
  }
}
.slide-heading {
  display: flex;
  align-items: baseline;
  margin-bottom: 45px;
  text-transform: capitalize;
}
.wall {
  width: 100%;
  height: 100vh;
  background-color: #212226;
  position: relative;
  .background {
  }
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.catalog {
  padding: 107px 0;
  background-color: #212226;
  .grid {
    display: flex;
    justify-content: space-between;
    .item-best {
      position: relative;
      width: 55%;
      background-color: #242529;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .background-animate {
        position: absolute;
        transform: translate(-390px, 294px);
        transition: 1s ease-in-out;
        &.active {
          transform: translate(-290px, 194px);
        }
        //width: 100%;
        //height: 100%;
      }
      .content {
        position: relative;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 50px;
        width: 100%;
        overflow: hidden;
        .type {
          position: relative;
          color: #fff;
          font-family: "Garamond";
          font-size: 15px;
          letter-spacing: 0.4px;
          line-height: 18px;
          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            margin: 0 15px;
            width: 1000px;
            height: 1px;
            background-color: #fff;
          }
          &:after {
            left: 100%;
          }
          &:before {
            right: 100%;
          }
        }
        .heading {
          font-size: 30px;
          font-weight: 500;
          color: #fff;
          line-height: 36px;
          text-align: center;
          margin: 0;
          letter-spacing: 0.4px;
          margin: 5px 0 20px 0;
          font-family: "Ubuntu";
        }
        .line {
          width: 100%;
          height: 1px;
          background-color: #fff;
        }
        .more {
          text-transform: capitalize;
          width: 242px;
          height: 42px;
          border-radius: 24px;
          background: transparent;
          font-size: 14px;
          font-weight: 300;
          margin: 60px 0 0 0;
          transition: 1s;
          color: #000;
          background-color: #fff;
          &:hover {
            background-color: #e0e7f0;
          }
        }
      }
    }
    .sub-list {
      width: 45%;
      padding-left: 30px;
      .item {
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 110px;
        max-height: 14vh;
        margin-bottom: 30px;
        background-color: #242529;
        border-radius: 4px;
        font-size: 19px;
        color: #fff;
        text-align: center;
        transition: 1s ease;
        font-weight: 400;
        &:last-of-type {
          margin-bottom: 0;
        }
        & > * {
          transition: 1s;
        }
        .picture {
          opacity: 0.3;
        }
        .over {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: -1;
          opacity: 0;
          button {
            width: 177px;
            height: 35px;
            border-radius: 18px;
            background: transparent;
            font-size: 14px;
            font-weight: 300;
            color: #000;
            background-color: #fff;
            transition: 1s;
            text-transform: capitalize;
          }
        }
        &:hover {
          picture {
            opacity: 0.2;
          }
          .name {
            opacity: 0;
          }
          .over {
            opacity: 1;
            z-index: 20;
          }
        }
      }
    }
  }
}
.view-project {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .slick-dots {
      margin: 0;
      height: 50px;
      transform: translatey(-100%);
    }
  }
  .close {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 40px;
    height: 40px;
    z-index: 99;
    cursor: pointer;
    &:hover {
      div {
        opacity: 0.8;
      }
    }
    div {
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      background-color: #fff;
      opacity: 0.5;

      &:nth-of-type(1) {
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        transform: rotate(-45deg);
      }
    }
  }
  .slide {
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.slick-dots {
  position: absolute;
  margin: 30px 0 0 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  li {
    padding: 5px;
    width: 6px;
    height: 6px;
    box-sizing: content-box;
    list-style: none;
    cursor: pointer;
    &.slick-active {
      button {
        background-color: #fff;
        box-shadow: 0 -1px 7px 0 rgba(255, 255, 255, 0.5);
      }
    }
    button {
      transition: 0.5s;
      font-size: 0;
      width: 100%;
      height: 100%;
      background-color: #454545;
      border-radius: 50%;
      padding: 0;
      display: block;
    }
  }
}
.projects {
  padding: 107px 0;
  background-color: #242529;
  .projects-wrap {
    margin-bottom: 46px;
    position: relative;
    .slideshow {
      margin: 0 -15px;
      .slick-slide {
        padding: 0 15px;
        height: inherit !important;
      }
      .slide {
        overflow: hidden;
        background-color: #000;
        padding: 53px 30px 40px 30px;
        color: #fff;
        min-height: 250px;
        height: 100%;
        border-radius: 4px;
        position: relative;
        flex: 1;
        .content {
          position: relative;
          display: flex;
          z-index: 10;
          flex-direction: column;
          height: 100%;
          .type {
            font-size: 14px;
          }
          .title {
            font-size: 22px;
            font-weight: 600;
            padding: 5px 0 10px 0;
          }
          .description {
            font-size: 13px;
            line-height: 17px;
          }
          .more {
            font-weight: 600;
            letter-spacing: -0.5px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-self: flex-end;
            margin-top: auto;
            cursor: pointer;
            padding-top: 20px;
            div {
              margin-right: 11px;
              text-transform: capitalize;
            }
            svg {
              transform: rotate(180deg);
              height: 9px;
              path {
                stroke: #fff;
              }
            }
          }
        }
        picture {
          opacity: 0.4;
        }
      }
    }
  }
}
.advantages {
  padding: 107px 0;
  background-color: #212226;
  .advantage-grid {
    display: flex;
    flex-wrap: wrap;
    .advantage-block {
      margin: 0 0 30px 0;
      display: flex;
      flex-direction: column;
      padding: 35px 70px 0 70px;
      width: calc(50% - 15px);
      height: 250px;
      align-items: center;
      text-align: center;
      background-color: #292a30;
      border-radius: 4px;
      &:nth-of-type(odd) {
        margin-right: 30px;
      }
      .advant-logo {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 2px solid #8f939f;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          height: 30px;
          width: auto;
          fill: #8f939f;
        }
      }
      .advant-heading {
        font-size: 20px;
        color: #fff;
        font-weight: 400;
        margin: 10px 0;
      }
      .advant-text {
        color: #fff;
        opacity: 0.75;
        letter-spacing: 0;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}
.process {
  padding: 107px 0;
  background-color: #212226;
  .steps {
    display: flex;
    flex-wrap: wrap;
    .step-container {
      display: flex;
      align-items: center;
      width: 50%;
      height: 110px;
      &:nth-of-type(odd) {
        padding-right: 20px;
      }
      .circle {
        font-family: "Cinzel", serif;
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        border: solid 2px #b8bac1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #b8bac1;
        font-size: 26px;
      }
      .text {
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        margin-left: 20px;
        max-width: 400px;
        font-weight: 400;
      }
    }
  }
}
.discounts {
  padding: 107px 0;
  background-color: #212226;
  .discount-container {
    border-radius: 4px;
    width: 100%;
    //min-height: 163px;
    cursor: pointer;
    position: relative;
    padding: 40px 70px;
    overflow: hidden;
    margin-bottom: 20px;
    min-height: 90px;
    picture {
      opacity: 0.3;
    }
    .content {
      padding: 20px 0;
      position: relative;
      z-index: 10;
      min-height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .heading {
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;
        color: #fff;
      }
      .read-more {
        width: 238px;
        min-width: 238px;
        height: 38px;
        border-radius: 19px;
        font-size: 14px;
        color: #000;
        background: #fff;
        transition: 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          display: inline-block;
          transform: rotate(-90deg);
          margin-left: 10px;
          height: 12px;
        }
        &:hover {
          background-color: #e0e7f0;
        }
      }
    }
    &.active {
      .hidden {
        max-height: 600px;
        min-height: 300px;
      }
      .content {
        .read-more {
          color: #fff;
          border: 2px solid #8f939f;
          background: transparent;
          svg {
            path {
              stroke: inherit;
            }
            stroke: #fff;
            transform: rotate(90deg);
          }
        }
      }
    }
    .hidden {
      max-height: 0;
      overflow: hidden;
      z-index: 10;
      position: relative;
      max-width: 70%;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      letter-spacing: 1px;
      font-weight: 300;
      transition: 1s;
      min-height: 0;
      p {
        margin-top: 0;
      }
    }
  }
}
.contacts {
  padding: 107px 0 162px 0;
  background-color: #242529;
  .contacts-container {
    display: flex;
    .map {
      width: 50%;
      border-radius: 4px;
      min-height: 400;
      overflow: hidden;
    }
    .contact-info {
      margin-left: 40px;
      .logo {
        margin-bottom: 30px;
      }
      .content {
        .heading {
          font-weight: 500;
          color: #fff;
          font-size: 18px;
          line-height: 20px;
        }
        p {
          font-size: 12px;
          color: #b8bac1;
          letter-spacing: 1px;
          margin: 0;
          line-height: 19px;

          span {
            font-weight: 300;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) and (min-width: 300px) {
  .container {
    padding: 0 calc(100vw / 320 * 20);
  }
  .slide-heading {
    align-items: center;
  }
  .slide-number {
    margin-left: calc(100vw / 320 * 5);
    .current {
      font-size: calc(100vw / 320 * 35);
    }
    .total {
      font-size: calc(100vw / 320 * 13);
    }
  }
  h2 {
    font-size: calc(100vw / 320 * 28);
    line-height: calc(100vw / 320 * 28);
  }
  .wall {
    height: 100vh;
    h1 {
      font-size: calc(100vw / 320 * 30);
      line-height: calc(100vw / 320 * 36);
    }
  }
  .catalog {
    padding: calc(100vw / 320 * 30) 0;
    .grid {
      flex-wrap: wrap;
      .item-best {
        width: 100%;
        height: calc(100vw / 320 * 330);
        min-height: auto;
        max-height: 500px;
        margin-bottom: calc(100vw / 320 * 20);
        .content {
          margin: 0 calc(100vw / 320 * 15);
          .type {
            font-size: calc(100vw / 320 * 15);
            line-height: calc(100vw / 320 * 15);
          }
          .heading {
            font-size: calc(100vw / 320 * 25);
            line-height: calc(100vw / 320 * 30);
          }
        }
      }
      .sub-list {
        padding: 0;
        width: 100%;
        .item {
          margin-bottom: calc(100vw / 320 * 20);
        }
      }
    }
  }
  .projects {
    padding: calc(100vw / 320 * 40) 0;
  }
  .advantages {
    padding: calc(100vw / 320 * 30) 0;
    .advantage-grid {
      .advantage-block {
        margin: 0 0 calc(100vw / 320 * 20) 0 !important;
        padding: calc(100vw / 320 * 30) calc(100vw / 320 * 20);
        height: auto;
        width: 100%;
        margin: 0 auto 20px auto;
      }
    }
  }
  .process {
    padding: calc(100vw / 320 * 30) 0;
    .steps {
      .step-container {
        width: 100%;
        flex-direction: column;
        text-align: center;
        height: auto;
        margin-bottom: 20px;
        &:nth-of-type(odd) {
          padding-right: 0;
        }
        .circle {
          margin-bottom: 10px;
        }
        .text {
          margin: 0;
        }
      }
    }
  }
  .discounts {
    padding: calc(100vw / 320 * 30) 0;

    .discount-container {
      padding: calc(100vw / 320 * 25) calc(100vw / 320 * 20)
        calc(100vw / 320 * 25) calc(100vw / 320 * 20);
      min-height: auto;
      .content {
        padding: 0;
        min-height: auto;
        .heading {
          font-size: calc(100vw / 320 * 22);
          line-height: calc(100vw / 320 * 26);
          padding-right: 20px;
        }
        .read-more {
          width: calc(100vw / 320 * 30);
          min-width: calc(100vw / 320 * 30);
          height: calc(100vw / 320 * 30);
          font-size: 0;
          max-width: 40px;
          max-height: 40px;
          span {
            display: none;
          }
          svg {
            margin-left: 0;
          }
        }
      }
      .hidden {
        max-width: 100%;
      }
      &.active {
        .hidden {
          padding: 20px 0 0 0;
          min-height: 0;
        }
      }
    }
  }
  .contacts {
    padding: calc(100vw / 320 * 30) 0 100px 0;
    .contacts-container {
      flex-wrap: wrap;
      .map {
        width: 100%;
        height: calc(100vw / 320 * 400);
      }
      .contact-info {
        margin: calc(100vw / 320 * 20) auto 0 auto;
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 1000px) and (min-width: 750px) {
  .advantages {
    .advantage-grid {
      .advantage-block {
        padding: 30px 30px 10px 30px;
        height: 280px;
      }
    }
  }
  .contacts {
    padding: calc(100vw / 320 * 30) 0 100px 0;
    .contacts-container {
      flex-wrap: wrap;
      .map {
        width: 100%;
        height: calc(100vw / 750 * 500);
      }
      .contact-info {
        margin: calc(100vw / 320 * 20) auto 0 auto;
        text-align: center;
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 700px) {
  .slide-heading {
    margin-bottom: 30px;
  }
  .catalog {
    .grid {
      .sub-list {
        padding-left: 20px;
        .item {
          margin-bottom: 20px;
        }
      }
    }
  }
  .advantages {
    .advantage-grid {
      .advantage-block {
        margin: 0 0 20px 0;
        padding: 25px 60px 0 60px;
        height: 220px;
        &:nth-of-type(odd) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
