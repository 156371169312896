import Vue from "vue";
import { getPages, getProjects } from "../api/config";
import Vuex from "vuex";
import router from "../router";
Vue.use(Vuex);

const state = () => ({
  locales: ["lv", "ru"],
  locale: "lv",
  fields: {},
  projects: [],
  singlePage: {},
  pageUpdate: 0,
});

const mutations = {
  SET_LANG(state, locale) {
    if (state.locales.indexOf(locale) !== -1) {
      state.locale = locale;
    }
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_PAGES(state, pages) {
    state.fields = pages;
  },
  SET_SINGLE_PAGE(state, props) {
    console.log(`SET_SINGLE_PAGE`, props);
    const { pages, id } = props;
    state.singlePage[id] = pages;
    state.pageUpdate = state.pageUpdate + 1;
  },
};
const actions = {
  setProjects: async ({ commit }) => {
    const projects = await getProjects();
    commit("SET_PROJECTS", projects);
  },
  setPages: async ({ commit }) => {
    const pages = await getPages();
    commit("SET_PAGES", pages);
  },
  setSinglePage: async ({ commit }, id) => {
    if (!!id) {
      const pages = await getPages(id);
      commit("SET_SINGLE_PAGE", { pages, id });
    }else{
      router.push('/404')
    }
  },
};

export const store = new Vuex.Store({
  state,
  mutations,
  actions,
});
